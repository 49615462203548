import styled from 'styled-components';
import FooterImage from './footer-bg.png';

export const FooterWrapper = styled.div`
  padding: 40px 0;
  text-align: center;
  background: #efefef;
  background-image: url(${FooterImage});
  background-repeat: no-repeat;
  background-position: center 50px;
  border-top: 1px solid #efefef;
  overflow: hidden;
  @media (max-width: 990px) {
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
`;
