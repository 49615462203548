import React from 'react';
import { Provider as KolskiNalogProvider } from './KolskiNalogContext';
import WindowDimensionsProvider from './WindowDimensionsContext';
import { Provider as VoziloProvider } from './VoziloContext';
import { Provider as ServisProvider } from './ServisContext';
import { Provider as AuthProvider } from './AuthContext';
import { Provider as AbilityProvider } from './AbilityContext';

const Provider = ({ children }) => {
  return (
    <AuthProvider>
      <WindowDimensionsProvider>
        <ServisProvider>
          <VoziloProvider>
            <KolskiNalogProvider>
              <AbilityProvider>{children}</AbilityProvider>
            </KolskiNalogProvider>
          </VoziloProvider>
        </ServisProvider>
      </WindowDimensionsProvider>
    </AuthProvider>
  );
};

export default Provider;
