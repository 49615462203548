import React, { createContext, useContext, useState } from 'react';
import useSessionStorage from '../components/useSessionStorage';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children, keycloak } = props;

  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);

  const toggleModal = () => setShowModal((s) => !s);

  const [pagination, setPagination] = useSessionStorage('paginationNalozi');

  const [filter, setFilter] = useSessionStorage('filterNalozi');

  // Make the context object:
  const context = {
    keycloak,
    showModal,
    toggleModal,
    action,
    setAction,
    editingRecord,
    setEditingRecord,
    pagination,
    setPagination,
    filter,
    setFilter,
  };

  // pass the value in provider and return
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useKolskiNalogContext = () => useContext(Context);
