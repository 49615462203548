import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Spinner = ({ small = false }) => {
  return (
    <SpinnerContainer>
      <Spin size={small ? 'small' : 'large'} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`;

export default Spinner;
