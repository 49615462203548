import React from 'react';
import { Route, Redirect } from 'react-router';
import { useAuthContext } from '../context/AuthContext';
import { Calendarllustration } from './Illustration';
import moment from 'moment';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authUser, isAuthReady } = useAuthContext();
  if (!isAuthReady && !authUser) return null;

  if (authUser && authUser.role === 'guest') {
    if (
      !(
        moment(authUser.validFrom, 'DD-MM-YYYY') <= moment() &&
        moment(authUser.validUntil, 'DD-MM-YYYY') >= moment()
      )
    )
      return (
        <Calendarllustration
          small={true}
          text="Vama predviđeno vrijeme za korištenje aplikacije je isteklo."
        />
      );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
