import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import firebase from 'firebase/app';

const Provider = ({ children, keycloak }) => {
  const client = new ApolloClient({
    uri:
      process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:5000/graphql',
    fetchOptions: {
      credentials: 'include',
    },
    request: async (operation) => {
      try {
        const currentUser = firebase.auth().currentUser;
        let token = '';
        if (currentUser) {
          token = await currentUser.getIdToken(true);
        }
        operation.setContext({
          headers: {
            authorization: token,
          },
        });
      } catch (error) {
        console.log('Greška-', error.message);
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
