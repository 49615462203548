import React from 'react';
import InfoStudioLogo from './infostudio-logo.png';
import { FooterWrapper } from './style';

const Footer = () => {
  const date = new Date(); // 20th January 2021
  const year = date.getFullYear();
  return (
    <FooterWrapper>
      <div>
        <img src={InfoStudioLogo} alt="logo" />
      </div>
      Copyright © {year} Info Studio d.o.o. All Rights Reserved.
    </FooterWrapper>
  );
};

export default Footer;
