import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import {
  LayoutWrapper,
  CenteredContainer,
  ContentWrapper,
  MobileContentWrapper,
} from './style';
import { useLocation } from 'react-router-dom';
import ResponsiveLayout from '../ResponsiveLayout';

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <LayoutWrapper>
      {location.pathname !== '/login' && <Navbar />}
      {children}
      <Footer />
    </LayoutWrapper>
  );
};

export const Content = ({ children }) => {
  return (
    <ResponsiveLayout
      renderDesktop={
        <CenteredContainer>
          <ContentWrapper>{children}</ContentWrapper>
        </CenteredContainer>
      }
      renderMobile={<MobileContentWrapper>{children}</MobileContentWrapper>}
    />
  );
};

export * from './style';
export default Layout;
