import React, { createContext, useContext, useState } from 'react';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

 
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);

  const toggleModal = () => setShowModal((s) => !s);

  // Make the context object:
  const context = {
    showModal,
    toggleModal,
    action,
    setAction,
    editingRecord,
    setEditingRecord
  };

  // pass the value in provider and return
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useServisContext = () => useContext(Context);
